nav {
  background-color: #cb2d6f;
  overflow: hidden;
}

h1 {
  font-size: 12px;
  margin: 0;
}

h2 {
  font-size: 2em;
  text-align: center;
}

h3 {
  font-size: 1.5;
}

.leftSide {
  font-size: 1.75em;
  line-height: 1.5em;
  text-align: justify;
  border-radius: 5%;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

@media (min-width: 320px) and (max-width: 480px) {
  .leftSide {
    font-size: 1.5em;
  }
}

.link {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

.link {
  position: relative;
  z-index: 1;
  display: inline-flex;

  padding-left: 10px;
  padding-bottom: 5px;
  padding-right: 10px;
}

.link::before {
  content: "";
  width: 100%;
  height: 50%;
  background-image: linear-gradient(to top, #cb2d6f 25%, rgba(0, 0, 0, 0) 40%);
  /* #fe30b9 */
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: -1;
  will-change: width;
  transform: rotate(-2deg);
  transform-origin: left bottom;
}

.link:hover::before {
  width: 0;
}

/*
* animation styles
*/

.link::before {
  transition: width 0.1s ease-out;
}

.link:hover::before {
  transition-duration: 0.15s;
}

.pinkh5 {
  /* color: #cb2d6f; */
  color: #cb2d6f;
  font-weight: 400;
  font-size: 1.5em;
}

span {
  color: #cb2d6f;
}

ul {
  font-family: "Ovo", serif;
}
ul li {
  font-family: Montserrat !important;
}

button {
  background-color: #cb2d6f;
  color: white;
}
button:hover {
  /* background-color: #91e7c3 !important; */
  background-color: #08231e;
}

.container {
  width: 100vw;
}
